.main {
    width: 100%;
}

.container {
    display: block;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 24px;

    @include media-query(lg) {
        padding: 0 24px;
    }

    &--large {
        max-width: 1200px;
    }

    &--medium {
        max-width: 960px;
    }

    &--small {
        max-width: 800px;
    }
}

.section {
    padding: 3.2rem 0;

    @include media-query(sm) {
        padding: 6.4rem 0;
    }
}

.bg {
    &--sun {
        background-color: $color_sun;
        background-image: linear-gradient(to right, $color_sun, $color_orange);
        color: #fff;
    }

    &--azure {
        background-color: $color_azure;
    }
}

.pagetitle {
    text-align: center;
}

.rtxt {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul {
        &:last-child {
            margin-bottom: 0;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 0.8em;

        small {
            font-size: 66.66%;
            color: $color_sun;
        }
    }

    *:not(.subtitle) + {
        & h1,
        & h2,
        & h3,
        & h4,
        & h5,
        & h6 {
            margin-top: 1.2em;
        }
    }

    p,
    ol,
    ul {
        margin-bottom: 1.2em;
    }

    ol,
    ul {
        padding-left: 1em;
    }

    ol {
        list-style: decimal;
    }

    ul {
        list-style: disc;
    }

    li {
        padding-left: 0.4rem;

        & + li {
            margin-top: 0.8rem;
        }
    }

    a:not([class]) {
        text-decoration: underline;
    }
}

.address {
    font-style: normal;

    h4 {
        margin-bottom: 0.8em;
    }

    li {
        display: flex;
        align-items: center;

        & + li {
            margin-top: 0.8rem;
        }
    }

    &__icon {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        fill: $color_marine;
        margin-right: 0.8rem;
    }
}

.footer .address__icon {
    fill: $color_white;
}

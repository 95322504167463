.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #fff;

    @include media-query(lg) {
        position: static;
        top: auto;
        left: auto;
        z-index: 0;
    }

    &__top {
        display: none;
        background-color: $color_marine;
        color: #fff;

        @include media-query(lg) {
            display: block;
        }

        .container {
            display: flex;
            align-items: center;
        }
    }

    &__bottom {
        border-bottom: 1px solid $color_lightgrey;

        .container {
            display: flex;
            align-items: stretch;
        }
    }

    &__phone-numbers {
        font-style: normal;

        ul {
            display: flex;
            align-items: center;
        }
    }

    &__logo {
        margin: 1.2rem auto 1.2rem 0;

        @include media-query(lg) {
            margin: 1.6rem auto 1.6rem 0;
        }
    }
}

.logo {
    flex: 0 0 auto;
    display: inline-flex;
    justify-self: center;
    align-items: center;

    &__image {
        width: 8rem;

        @include media-query(lg) {
            width: 16rem;
        }
    }
}

.nav--main {
    margin: 0 auto;
    display: none;

    @include media-query(lg) {
        display: block;
    }

    > ul {
        display: flex;
        align-items: stretch;
        height: 100%;
    }

    > ul > li {
        position: relative;
        display: flex;
        align-items: center;

        &.is-active {
            &:after {
                content: "";
                display: block;
                width: 100%;
                height: 0.3rem;
                background-color: $color_sun;
                position: absolute;
                bottom: 0;
            }
        }

        &:hover {
            > ul {
                clip: auto;
                clip-path: none;
                overflow: visible;
                white-space: normal;
                width: auto;
                height: auto;
            }
        }
    }

    > ul > li > a {
        display: inline-block;
        padding: 0.8rem 1.2rem;
        font-size: 1.6rem;
        font-weight: 600;
        text-align: center;
        color: $color_ocean;
        transition: color 0.2s ease;

        &:hover {
            color: $color_sun;
        }
    }

    > ul > li > .nav__sub-toggle {
        display: none;
    }

    > ul > li {
        > ul {
            position: absolute;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            overflow: hidden;
            white-space: wrap;
            width: 1px;
            height: 1px;
            z-index: 99;
            top: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            background-color: $color_white;
            border: 1px solid $color_lightgrey;
        }

        > ul > li {
            border-top: 1px solid $color_lightgrey;

            &:first-child {
                border-top: 0 none;
            }
        }

        > ul > li > a {
            display: block;
            padding: 8px 16px;
            font-size: 90%;
            font-weight: 600;

            &:hover {
                background-color: lighten($color_lightgrey, 10%);
                text-decoration: underline;
            }
        }
    }
}

.nav--sub {
    margin-left: auto;

    > ul {
        display: flex;
        align-items: center;
    }

    > ul > li {
        border-left: 1px solid #fff;

        &:last-child {
            border-right: 1px solid #fff;
        }
    }

    > ul > li > a {
        display: inline-block;
        padding: 0.8rem 1.6rem;
        font-size: 1.4rem;
        transition: background-color 0.2s ease;

        &:hover {
            background-color: lighten($color_marine, 10%);
        }
    }
}

.nav--client {
    display: none;
    align-items: center;
    margin-left: auto;

    @include media-query(lg) {
        display: flex;
    }

    ul {
        display: flex;
        align-items: center;
    }

    li {
        & + li {
            margin-left: 1.6rem;
        }
    }
}

.phone-number {
    &__link {
        display: inline-flex;
        align-items: center;
        padding: 0.8rem;
    }

    &__icon {
        flex: 0 0 auto;
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: 0.8rem;
        fill: #fff;
    }

    &__label {
        display: inline-block;
        font-size: 1.4rem;
    }
}

.nav {
    &--mobile {
        display: flex;
        align-items: center;
        margin-right: -16px;

        @include media-query(lg) {
            display: none;
        }

        ul {
            display: flex;
            align-items: center;
        }
    }

    &__toggle {
        border-radius: 0;

        svg:last-child {
            display: none;
        }

        &.is-active {
            background-color: $color_marine;

            svg:first-child {
                display: none;
            }

            svg:last-child {
                display: block;
                fill: #fff;
                width: 1.2rem;
                height: 1.2rem;
            }
        }
    }

    &__wrapper--mobile {
        position: fixed;
        z-index: 99;
        top: 4.8rem;
        bottom: 0;
        left: 0;
        width: 100%;
        background-color: $color_marine;
        color: #fff;
        display: none;
        overflow: auto;

        &.is-active {
            display: block;
        }

        > ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        > ul > li {
            position: relative;
            width: 100%;
            border-bottom: 1px solid rgba(#fff, 0.2);
        }

        > ul > li > a {
            display: block;
            padding: 1.2rem 1.6rem;
        }

        > ul > li > .nav__sub-toggle {
            position: absolute;
            top: 0;
            right: 0;
            font-size: 1.5rem;
            line-height: 1.75;
            color: $color_white;
            padding: 1.2rem 1.6rem;
            border: 0;
            background-color: transparent;

            svg {
                display: inline-block;
                width: 1.2rem;
                height: 1.2rem;
                fill: $color_white;

                &:last-child {
                    display: none;
                }
            }

            &.is-active {
                svg {
                    &:first-child {
                        display: none;
                    }
    
                    &:last-child {
                        display: inline-block;
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        > ul > li {
            > ul {
                display: flex;
                flex-direction: column;
                position: absolute;
                clip: rect(0 0 0 0);
                clip-path: inset(50%);
                overflow: hidden;
                white-space: wrap;
                width: 1px;
                height: 1px;

                &.is-active {
                    position: static;
                    clip: auto;
                    clip-path: none;
                    overflow: visible;
                    white-space: normal;
                    width: auto;
                    height: auto;
                }
            }

            > ul > li {
                width: 100%;
                border-top: 1px dashed rgba(#fff, 0.1);
            }

            > ul > li > a {
                display: block;
                font-size: 90%;
                padding: 1.2rem 1.6rem;
            }
        }
    }
}

.paragraph {
    width: 100%;
    padding-top: 3.6rem;

    &:last-child {
        padding-bottom: 3.6rem;
    }

    @include media-query(sm) {
        &.call-to-action:last-child {
            padding-bottom: 7.2rem;
        }
    }

    @include media-query(lg) {
        padding-top: 6.4rem;

        &:last-child {
            padding-bottom: 6.4rem;
        }

        &.call-to-action:last-child {
            padding-bottom: 12.8rem;
        }
    }
}

.timeline-w-image,
.timeline-w-brands {
    position: relative;

    &:after {
        content: "";
        display: block;
        width: 2px;
        background-color: transparent;
        background-image: linear-gradient(to bottom, $color_orange, $color_orange 50%, #fff 50%, #fff);
        background-size: 100% 20px;
        position: absolute;
        top: 50%;
        left: calc(16px + 3rem);
        bottom: -3.6rem;

        @include media-query(sm) {
            left: 50%;
            transform: translateX(-50%);
            bottom: -6.4rem;
        }
    }

    & + & {
        &:before {
            content: "";
            display: block;
            width: 2px;
            background-color: transparent;
            background-image: linear-gradient(to bottom, $color_orange, $color_orange 50%, #fff 50%, #fff);
            background-size: 100% 20px;
            position: absolute;
            top: 0;
            bottom: 50%;
            left: calc(16px + 3rem);

            @include media-query(sm) {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    &.is-last-timeline-item {
        &:after {
            content: none;
        }
    }

    &--left {
        .grid {
            @include media-query(sm) {
                flex-direction: row-reverse;
            }
        }
    }

    .grid {
        align-items: center;
    }

    [class*="grid-item"] {
        &:last-child {
            display: none;

            @include media-query(sm) {
                display: block;
            }
        }
    }

    &__body {
        margin-left: 7.6rem;

        @include media-query(sm) {
            margin-left: 0;
        }
    }

    &__icon {
        width: 6rem;
        height: 6rem;
        border-radius: 4rem;
        background-color: $color_orange;
        position: absolute;
        z-index: 1;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-query(sm) {
            width: 8rem;
            height: 8rem;
            left: 50%;
            transform: translate(-50%, -50%);

            img {
                width: 4rem;
                height: 4rem;
            }
        }

        img {
            flex: 0 0 auto;
            display: block;
            width: 3rem;
            height: 3rem;
            fill: #fff;
        }
    }
}

.timeline-w-image {
    &__image {
        display: block;
        width: 64rem;
        max-width: 100%;
        margin: 0 auto;

        &--rounded {
            border-radius: 50%;
            width: 48rem;
        }
    }
}

.timeline-w-brands {
    &__list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        margin: 0 -2rem;

        li {
            padding: 1rem 2rem;
        }
    }
}

.timeline-stop {
    position: relative;

    &__body {
        max-width: 48rem;
        margin: 0 auto;
        padding-top: 9.2rem;

        @include media-query(sm) {
            text-align: center;
            padding-top: 12.8rem;
        }
    }

    &__icon {
        width: 6rem;
        height: 6rem;
        border-radius: 4rem;
        background-color: $color_orange;
        position: absolute;
        z-index: 1;
        top: 3.6rem;
        left: 16px;
        display: flex;
        justify-content: center;
        align-items: center;

        @include media-query(sm) {
            width: 8rem;
            height: 8rem;
            left: 50%;
            transform: translateX(-50%);

            img {
                width: 4rem;
                height: 4rem;
            }
        }

        img {
            flex: 0 0 auto;
            display: block;
            width: 3rem;
            height: 3rem;
            fill: #fff;
        }
    }
}

.quote {
    position: relative;
    width: 100%;

    .grid {
        //min-height: 48rem;
        align-items: center;
    }

    &__quote {
        width: 4.4rem;
        height: 4.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        border-radius: 0.5rem;
        background-color: $color_lightgrey;
        font-weight: bold;
        color: #fff;
    }

    &__quote-icon {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: #fff;
    }

    // &__image {
    //     margin-left: -24px;
    //     height: 48rem;
    //     border-top-right-radius: 30rem;
    //     border-bottom-right-radius: 30rem;
    //     background-color: $color_clouds;
    //     background-size: cover;
    //     background-position: center;

    //     @include media-query(sm) {
    //         position: absolute;
    //         top: 50%;
    //         left: 0;
    //         transform: translateY(-50%);
    //         width: calc(50% - 24px);
    //         margin-left: 0;
    //     }
    // }
}

.simple-quote {
    &__body {
        padding: 3.6rem;
        background-color: $color_marine;
        color: #fff;

        @include media-query(lg) {
            padding: 4.8rem;
        }
    }
}

.teammember {
    &__image {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        margin: 2rem 0;
    }

    &__body {
        text-align: center;
    }

    &__function {
        font-weight: 500;
        color: $color_sun;
    }

    &__prop {
        margin-top: 0.8rem;
    }

    &__prop-icon {
        display: inline-block;
        vertical-align: middle;
        width: 1.6rem;
        height: 1.6rem;
        fill: $color_orange;
        margin-right: 0.8rem;
    }
}

.call-to-action {
    @include media-query(sm) {
        padding-top: 12.8rem;
        padding-bottom: 6.4rem;
    }

    &__wrapper {
        position: relative;
    }

    &__body {
        background-color: $color_marine;
        color: #fff;
        padding: 3.6rem;

        @include media-query(sm) {
            padding: 4.8rem 50% 4.8rem 4.8rem;
        }
    }

    &__image {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        z-index: 0;

        &:after {
            content: "";
            display: block;
            width: 100%;
            height: 50%;
            background-color: $color_marine;
            position: absolute;
            z-index: -1;
            bottom: 0;
            left: 0;

            @include media-query(sm) {
                content: none;
            }
        }

        @include media-query(sm) {
            position: absolute;
            top: -3.6rem;
            bottom: -3.6rem;
            right: 0;
            width: 50%;
        }

        @include media-query(lg) {
            top: -6.4rem;
            bottom: -6.4rem;
        }

        img {
            width: 50%;
            height: 100%;
            object-fit: contain;

            @include media-query(sm) {
                width: 100%;
            }
        }
    }
}

.text-w-image {
    &--left {
        .grid {
            flex-direction: row-reverse;
        }
    }

    &__image {
        display: block;
        width: 64rem;
        max-width: 100%;
        margin: 0 auto;

        &--rounded {
            width: 32rem;
            border-radius: 50%;
        }
    }
}

.sectors {
    &__title {
        @include media-query(sm) {
            grid-column: 1 / span 2;
        }

        .blog + & {
            margin-top: 6.4rem;
        }
    }

    &__grid {
        &--overview {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 100%;

            @include media-query(xxl) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

.sector {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    border-radius: .5rem;
    box-shadow: 0 .4rem 1.6rem rgba(#000, .1);
    background-color: #fff;

    @include media-query(sm) {
        grid-template-columns: minmax(min-content, 320px) 1fr;
    }

    &:hover {
        .button--ghost {
            @extend .button--ghost:hover;
        }
    }

    &__title {
        margin-top: 0 !important;
    }

    &__image {
        flex: 0 0 auto;
        display: block;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        background-size: cover;
        background-position: center;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;

        @include media-query(sm) {
            border-top-right-radius: 0;
            border-bottom-left-radius: 0.5rem;
        }
    }

    &__body {
        display: block;
        width: 100%;
        padding: 2.4rem;
    }

    &__read-more {
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        text-decoration: underline;
        transition: color .2s ease;
    }

    &__read-more-icon {
        flex: 0 0 auto;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: $color_marine;
        margin-left: .8rem;
        transition: fill .2s ease;
    }
}
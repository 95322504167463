.footer {
    width: 100%;
    padding: 3.6rem 0;
    background-color: $color_marine;
    color: #fff;

    [class*="grid-item"] {
        padding-bottom: 3.2rem;
    }

    @include media-query(sm) {
        padding: 6.4rem 0;
    }

    &__logo {
        margin-bottom: 3.2rem;
    }

    &__title {
        font-weight: bold;
        margin-bottom: 1.2rem;

        * + & {
            margin-top: 3.2rem;
        }
    }

    &__divider {
        margin: 0 0 3.2rem;
        border: 0;
        border-top: 1px solid #fff;

        @include media-query(sm) {
            margin: 3.2rem 0 6.4rem;
        }
    }

    &__disclaimer {
        font-size: 1.2rem;
        text-align: center;
        line-height: 1.5;
        opacity: .66;
    }
}

.nav--footer {
    li {
        & + li {
            margin-top: 0.8rem;
        }
    }
}

.nav--social {
    ul {
        display: flex;
        align-items: center;
    }

    li {
        & + li {
            margin-left: 1.2rem;
        }
    }

    .social__icon {
        fill: #fff;
    }
}

.social__icon {
    width: 2rem;
    height: 2rem;
}

body {
    background-color: #fff;
    padding-top: 4.8rem;

    @include media-query(lg) {
        padding-top: 0;
    }
}

h1 {
    font-size: 3.2rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 4rem;
    }
}

h2 {
    font-size: 2.4rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 2.8rem;
    }
}

h3 {
    font-size: 1.8rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 2rem;
    }
}

h4 {
    font-size: 1.6rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 1.8rem;
    }
}

h5 {
    font-size: 1.4rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 1.6rem;
    }
}

h6 {
    font-size: 1.2rem;
    font-weight: bold;

    @include media-query(lg) {
        font-size: 1.4rem;
    }
}

.subtitle {
    font-size: 1.2rem;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: .05em;
    color: $color_sun;
    margin-bottom: 0;
}
.intro {
    position: relative;
    z-index: 0;
    width: 100%;
    display: flex;
    align-items: center;
    
    @include media-query(lg) {
        min-height: 75vh;
    }

    &__body {
        .scan & {
            display: none;

            @include media-query(lg) {
                display: block;
            }
        }

        @include media-query(lg) {
            position: relative;
        }
    }

    &__arrow {
        display: none;

        @include media-query(lg) {
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            width: 12rem;
        }
    }

    .grid {
        align-items: center;
    }
}

.intro-figure {
    &:after {
        @include media-query(lg) {
            content: '';
            display: block;
            width: 50%;
            position: absolute;
            z-index: -1;
            top: 0;
            right: 0;
            bottom: -6.4rem;
            background-image: url(../images/bg_logo.svg);
            background-size: contain;
            background-position: top right;
            background-repeat: no-repeat;
        }
    }
}

.steps {
    width: 100%;
    //background-color: $color_azure;

    &__footer {
        text-align: center;
    }
}

.step {
    &__indicator {
        width: 4.4rem;
        height: 4.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        border-radius: 0.5rem;
        background-color: $color_marine;
        font-weight: bold;
        color: #fff;
    }
}

.testimonials {
    width: 100%;

    .subtitle {
        color: inherit;
    }

    &__slider {
        position: relative;
        padding-bottom: 6rem;
    }

    &__slider-slides {
        margin: 0 -1rem;
    }

    &__slider-slide {
        padding: 0 1rem;
        outline: 0;
    }

    &__slider-button {
        position: absolute;
        z-index: 1;
        bottom: 0;
        width: 4.4rem;
        height: 4.4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 2.2rem;
        outline: 0;
        border: 1px solid rgba($color_marine, .15);
        background-color: transparent;
        transition: all 0.2s ease;

        .bg--sun & {
            border-color: rgba(#fff, .15);
        }

        &.is-hidden {
            display: none;
        }

        &:hover {
            background-color: $color_clouds;
            border-color: $color_clouds;
            cursor: pointer;

            .testimonials__slider-button-icon {
                fill: $color_sky;
            }

            .bg--sun & .testimonials__slider-button-icon {
                fill: $color_marine;
            }
        }

        &--next {
            right: 16px;
        }

        &--prev {
            left: 16px;
        }
    }

    &__slider-button-icon {
        flex: 0 0 auto;
        width: 1.6rem;
        height: 1.6rem;
        display: block;
        fill: $color_marine;
        transition: all 0.2s ease;

        .bg--sun & {
            fill: #fff;
        }
    }
}

.testimonial {
    position: relative;
    margin-top: 2.2rem;
    margin-bottom: 1.6rem;
    padding: 2.4rem;
    border-radius: 0.5rem;
    background-color: #fff;
    color: $color_marine;
    box-shadow: 0 0.4rem 1.6rem rgba(#000, 0.1);

    &__image {
        max-width: 50%;
        margin: 0 auto 2.4rem;

        img {
            border-radius: 50%;
        }
    }

    &__quote {
        position: absolute;
        top: -2.2rem;
        left: 2.4rem;
        width: 4.4rem;
        height: 4.4rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        padding: 0.8rem;
        border-radius: 0.5rem;
        background-color: $color_marine;
        font-weight: bold;
        color: #fff;
    }

    &__quote-icon {
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: #fff;
    }

    &__score-label {
        font-size: 1.2rem;
    }

    &__score-number {
        display: flex;
        align-items: center;
        font-weight: bold;
        color: $color_leafs;
    }

    &__score-stars {
        display: flex;
        align-items: center;
        margin-left: 0.8rem;
    }

    &__score-star-wrapper {
        position: relative;
        & + .testimonial__score-star-wrapper {
            margin-left: 0.4rem;
        }
    }

    &__score-star {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 2rem;
        height: 2rem;
        fill: $color_leafs;
    }

    &__score-star-background {
        display: block;
        width: 2rem;
        height: 2rem;
        fill: $color_clouds;
    }
}
.form {
    .umbraco-forms-field {
        margin-top: 2rem;

        &:first-child {
            margin-top: 0;
        }

        // &.fileupload {
        //     display: flex;
        //     align-items: center;
        // }
    }

    .grid {
        margin: 0 -1rem;
    }

    [class*="grid-item"] {
        padding: 0 1rem;

        & + [class*="grid-item"] {
            margin-top: 2rem;

            @include media-query(lg) {
                margin-top: 0;
            }
        }
    }

    &__field-wrapper {
        width: 100%;
        padding-top: .8rem;
    }

    &__header {
        margin-bottom: 3.2rem;
    }

    &__footer {
        margin-top: 3.2rem;
    }

    &__group {
        & + .form__group {
            margin-top: 2rem;
        }
    }

    &__label {
        display: block;
        font-weight: 500;

        .fileupload & {
            flex: 0 0 auto;
            padding-right: 1.6rem;
            padding-bottom: 0;

        }

        &.is-visible {
            display: block;
        }
    }

    &__tooltip {
        display: block;
        font-size: 1.2rem;
        color: $color_mouse;
    }

    &__input {
        display: block;
        width: 100%;
        border: 0;
        outline: 0;
        background-color: $color_clouds;
        padding: 1.2rem 1.6rem;
        border-radius: 0.5rem;
        font-family: "Montserrat", sans-serif;
        font-size: 1.5rem;

        &--textarea {
            min-height: 15rem;
        }
    }
}

.toggle {
    display: inline-flex;
    align-items: flex-start;

    &:hover {
        cursor: pointer;
    }

    &__input {
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
        height: 1px;
        overflow: hidden;
        position: absolute;
        white-space: nowrap;
        width: 1px;
    }

    &__switch {
        flex: 0 0 auto;
        display: inline-flex;
        background-color: $color_lightgrey;
        border-radius: .5rem;
        position: relative;
        margin-right: 1.2rem;

        &:after {
            content: '';
            width: 3.2rem;
            height: 3.2rem;
            display: block;
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            background-color: $color_lava;
            border-radius: .5rem;
            transition: transform .2s ease, background-color .2s ease;
        }

        :checked + & {
            &:after {
                background-color: $color_leafs;
                transform: translateX(3.2rem);
            }
        }
    }

    &__switch-icon {
        width: 1.6rem;
        height: 1.6rem;
        fill: #fff;
        display: block;
        margin: .8rem;
        position: relative;
        z-index: 1;

        &--times {
            padding: .2rem;
        }
    }

    &__label {
        margin-top: .4rem;
    }
}
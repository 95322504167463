.blogs {
    &__title {
        @include media-query(sm) {
            grid-column: 1 / span 2;
        }

        .blog + & {
            margin-top: 6.4rem;
        }
    }

    &__grid {
        &--overview {
            display: grid;
            grid-gap: 2rem;
            grid-template-columns: 1fr;
            grid-template-rows: 1fr;
            width: 100%;

            @include media-query(sm) {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

.blog {
    display: block;
    width: 100%;
    border-radius: .5rem;
    box-shadow: 0 .4rem 1.6rem rgba(#000, .1);
    background-color: #fff;

    &:hover {
        .button--ghost {
            @extend .button--ghost:hover;
        }
    }

    &--large {
        @include media-query(sm) {
            grid-column: 1 / span 2;
        }

        @include media-query(lg) {
            grid-row: 2 / span 2;
            grid-column: auto;
        }
    }

    &--small {
        display: flex;
        flex-wrap: wrap;

        @include media-query(lg) {
            flex-wrap: nowrap;
        }

        & + .recent-blog--small {
            margin-top: 4rem;
        }
    }

    &__title {
        margin-top: 0 !important;
    }

    &__date {
        display: flex;
        align-items: center;
        font-weight: 500;
        color: $color_mouse;
        margin-bottom: 1.2rem !important;
    }

    &__date-icon {
        display: block;
        fill: $color_mouse;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .8rem;
    }

    &__image {
        flex: 0 0 auto;
        display: block;
        width: 100%;
        padding-bottom: 50%;
        height: 0;
        background-size: cover;
        background-position: center;
        border-top-right-radius: 0.5rem;
        border-top-left-radius: 0.5rem;

        [class*="small"] & {
            @include media-query(lg) {
                width: 50%;
                height: 100%;
                padding-bottom: 0;
                border-top-right-radius: 0;
                border-bottom-left-radius: .5rem;
            }
        }
    }

    &__body {
        display: block;
        width: 100%;
        padding: 2.4rem;

        [class*="large"] & {
            // border-bottom-left-radius: 0.5rem;
            // border-bottom-right-radius: 0.5rem;
        }

        [class*="small"] & {
            // border-top-right-radius: 0.5rem;
            // border-bottom-right-radius: 0.5rem;
        }
    }

    &__read-more {
        display: inline-flex;
        align-items: center;
        font-weight: 500;
        text-decoration: underline;
        transition: color .2s ease;

        .recent-blog:hover & {
            text-decoration: none;
            color: $color_sun;
        }
    }

    &__read-more-icon {
        flex: 0 0 auto;
        display: block;
        width: 1.6rem;
        height: 1.6rem;
        fill: $color_marine;
        margin-left: .8rem;
        transition: fill .2s ease;

        .recent-blog:hover & {
            fill: $color_sun;
        }
    }
}
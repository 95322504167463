.contact {
    padding: 6.4rem 0;

    &__form {
        background-color: #fff;
        border-radius: .5rem;
        box-shadow: 0 .4rem 1.6rem rgba(#000, .1);
        padding: 2.4rem;

        @include media-query(lg) {
            padding: 4.8rem;
        }
    }

    &__header {
        margin-bottom: 3.2rem;
    }

    &__footer {
        margin-top: 3.2rem;
    }

    .address {
        & + .address {
            margin-top: 3.2rem;
        }
    }
}

.map {
    width: 100%;
    height: 24rem;
    background-color: $color_lightgrey;
    position: relative;

    @include media-query(lg) {
        height: 48rem;
    }

    iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: block;
        border: 0;
    }
}
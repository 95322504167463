html {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-size: 10px; }

*,
*:before,
*:after {
  box-sizing: inherit; }

body {
  width: 100%;
  min-height: 100%;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  line-height: 1.75;
  color: #2c3d5f; }

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ol,
ul {
  margin: 0;
  padding: 0;
  font-weight: normal; }

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.25;
  color: inherit; }

ol,
ul {
  list-style: none; }

img {
  max-width: 100%;
  height: auto; }

a {
  text-decoration: none;
  color: inherit; }

body {
  background-color: #fff;
  padding-top: 4.8rem; }
  @media (min-width: 1024px) {
    body {
      padding-top: 0; } }

h1 {
  font-size: 3.2rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h1 {
      font-size: 4rem; } }

h2 {
  font-size: 2.4rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h2 {
      font-size: 2.8rem; } }

h3 {
  font-size: 1.8rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h3 {
      font-size: 2rem; } }

h4 {
  font-size: 1.6rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h4 {
      font-size: 1.8rem; } }

h5 {
  font-size: 1.4rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h5 {
      font-size: 1.6rem; } }

h6 {
  font-size: 1.2rem;
  font-weight: bold; }
  @media (min-width: 1024px) {
    h6 {
      font-size: 1.4rem; } }

.subtitle {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: .05em;
  color: #b61615;
  margin-bottom: 0; }

.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin: 0 -24px; }

.grid-item-1 {
  width: 8.33333%;
  padding: 0 24px; }

.grid-item-2 {
  width: 16.66667%;
  padding: 0 24px; }

.grid-item-3 {
  width: 25%;
  padding: 0 24px; }

.grid-item-4 {
  width: 33.33333%;
  padding: 0 24px; }

.grid-item-5 {
  width: 41.66667%;
  padding: 0 24px; }

.grid-item-6 {
  width: 50%;
  padding: 0 24px; }

.grid-item-7 {
  width: 58.33333%;
  padding: 0 24px; }

.grid-item-8 {
  width: 66.66667%;
  padding: 0 24px; }

.grid-item-9 {
  width: 75%;
  padding: 0 24px; }

.grid-item-10 {
  width: 83.33333%;
  padding: 0 24px; }

.grid-item-11 {
  width: 91.66667%;
  padding: 0 24px; }

.grid-item-12 {
  width: 100%;
  padding: 0 24px; }

@media (min-width: 480px) {
  .grid-item-1--xs {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xs {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xs {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xs {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xs {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xs {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xs {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xs {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xs {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xs {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xs {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xs {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 640px) {
  .grid-item-1--sm {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--sm {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--sm {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--sm {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--sm {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--sm {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--sm {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--sm {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--sm {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--sm {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--sm {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--sm {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 768px) {
  .grid-item-1--md {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--md {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--md {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--md {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--md {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--md {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--md {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--md {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--md {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--md {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--md {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--md {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1024px) {
  .grid-item-1--lg {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--lg {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--lg {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--lg {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--lg {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--lg {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--lg {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--lg {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--lg {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--lg {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--lg {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--lg {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1280px) {
  .grid-item-1--xl {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xl {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xl {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xl {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xl {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xl {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xl {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xl {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xl {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xl {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xl {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xl {
    width: 100%;
    padding: 0 24px; } }

@media (min-width: 1600px) {
  .grid-item-1--xxl {
    width: 8.33333%;
    padding: 0 24px; }
  .grid-item-2--xxl {
    width: 16.66667%;
    padding: 0 24px; }
  .grid-item-3--xxl {
    width: 25%;
    padding: 0 24px; }
  .grid-item-4--xxl {
    width: 33.33333%;
    padding: 0 24px; }
  .grid-item-5--xxl {
    width: 41.66667%;
    padding: 0 24px; }
  .grid-item-6--xxl {
    width: 50%;
    padding: 0 24px; }
  .grid-item-7--xxl {
    width: 58.33333%;
    padding: 0 24px; }
  .grid-item-8--xxl {
    width: 66.66667%;
    padding: 0 24px; }
  .grid-item-9--xxl {
    width: 75%;
    padding: 0 24px; }
  .grid-item-10--xxl {
    width: 83.33333%;
    padding: 0 24px; }
  .grid-item-11--xxl {
    width: 91.66667%;
    padding: 0 24px; }
  .grid-item-12--xxl {
    width: 100%;
    padding: 0 24px; } }

.spt--none {
  padding-top: 0; }

.spt--xs {
  padding-top: 0.8rem; }

.spt--sm {
  padding-top: 1.6rem; }

.spt--md {
  padding-top: 2.4rem; }

.spt--lg {
  padding-top: 3.2rem; }

.spt--xl {
  padding-top: 4.8rem; }

.spt--xxl {
  padding-top: 6.4rem; }

.spt--xxxl {
  padding-top: 8rem; }

@media (min-width: 480px) {
  .spt--none--xs {
    padding-top: 0; }
  .spt--xs--xs {
    padding-top: 0.8rem; }
  .spt--sm--xs {
    padding-top: 1.6rem; }
  .spt--md--xs {
    padding-top: 2.4rem; }
  .spt--lg--xs {
    padding-top: 3.2rem; }
  .spt--xl--xs {
    padding-top: 4.8rem; }
  .spt--xxl--xs {
    padding-top: 6.4rem; }
  .spt--xxxl--xs {
    padding-top: 8rem; } }

@media (min-width: 640px) {
  .spt--none--sm {
    padding-top: 0; }
  .spt--xs--sm {
    padding-top: 0.8rem; }
  .spt--sm--sm {
    padding-top: 1.6rem; }
  .spt--md--sm {
    padding-top: 2.4rem; }
  .spt--lg--sm {
    padding-top: 3.2rem; }
  .spt--xl--sm {
    padding-top: 4.8rem; }
  .spt--xxl--sm {
    padding-top: 6.4rem; }
  .spt--xxxl--sm {
    padding-top: 8rem; } }

@media (min-width: 768px) {
  .spt--none--md {
    padding-top: 0; }
  .spt--xs--md {
    padding-top: 0.8rem; }
  .spt--sm--md {
    padding-top: 1.6rem; }
  .spt--md--md {
    padding-top: 2.4rem; }
  .spt--lg--md {
    padding-top: 3.2rem; }
  .spt--xl--md {
    padding-top: 4.8rem; }
  .spt--xxl--md {
    padding-top: 6.4rem; }
  .spt--xxxl--md {
    padding-top: 8rem; } }

@media (min-width: 1024px) {
  .spt--none--lg {
    padding-top: 0; }
  .spt--xs--lg {
    padding-top: 0.8rem; }
  .spt--sm--lg {
    padding-top: 1.6rem; }
  .spt--md--lg {
    padding-top: 2.4rem; }
  .spt--lg--lg {
    padding-top: 3.2rem; }
  .spt--xl--lg {
    padding-top: 4.8rem; }
  .spt--xxl--lg {
    padding-top: 6.4rem; }
  .spt--xxxl--lg {
    padding-top: 8rem; } }

@media (min-width: 1280px) {
  .spt--none--xl {
    padding-top: 0; }
  .spt--xs--xl {
    padding-top: 0.8rem; }
  .spt--sm--xl {
    padding-top: 1.6rem; }
  .spt--md--xl {
    padding-top: 2.4rem; }
  .spt--lg--xl {
    padding-top: 3.2rem; }
  .spt--xl--xl {
    padding-top: 4.8rem; }
  .spt--xxl--xl {
    padding-top: 6.4rem; }
  .spt--xxxl--xl {
    padding-top: 8rem; } }

@media (min-width: 1600px) {
  .spt--none--xxl {
    padding-top: 0; }
  .spt--xs--xxl {
    padding-top: 0.8rem; }
  .spt--sm--xxl {
    padding-top: 1.6rem; }
  .spt--md--xxl {
    padding-top: 2.4rem; }
  .spt--lg--xxl {
    padding-top: 3.2rem; }
  .spt--xl--xxl {
    padding-top: 4.8rem; }
  .spt--xxl--xxl {
    padding-top: 6.4rem; }
  .spt--xxxl--xxl {
    padding-top: 8rem; } }

.spb--none {
  padding-bottom: 0; }

.spb--xs {
  padding-bottom: 0.8rem; }

.spb--sm {
  padding-bottom: 1.6rem; }

.spb--md {
  padding-bottom: 2.4rem; }

.spb--lg {
  padding-bottom: 3.2rem; }

.spb--xl {
  padding-bottom: 4.8rem; }

.spb--xxl {
  padding-bottom: 6.4rem; }

.spb--xxxl {
  padding-bottom: 8rem; }

@media (min-width: 480px) {
  .spb--none--xs {
    padding-bottom: 0; }
  .spb--xs--xs {
    padding-bottom: 0.8rem; }
  .spb--sm--xs {
    padding-bottom: 1.6rem; }
  .spb--md--xs {
    padding-bottom: 2.4rem; }
  .spb--lg--xs {
    padding-bottom: 3.2rem; }
  .spb--xl--xs {
    padding-bottom: 4.8rem; }
  .spb--xxl--xs {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xs {
    padding-bottom: 8rem; } }

@media (min-width: 640px) {
  .spb--none--sm {
    padding-bottom: 0; }
  .spb--xs--sm {
    padding-bottom: 0.8rem; }
  .spb--sm--sm {
    padding-bottom: 1.6rem; }
  .spb--md--sm {
    padding-bottom: 2.4rem; }
  .spb--lg--sm {
    padding-bottom: 3.2rem; }
  .spb--xl--sm {
    padding-bottom: 4.8rem; }
  .spb--xxl--sm {
    padding-bottom: 6.4rem; }
  .spb--xxxl--sm {
    padding-bottom: 8rem; } }

@media (min-width: 768px) {
  .spb--none--md {
    padding-bottom: 0; }
  .spb--xs--md {
    padding-bottom: 0.8rem; }
  .spb--sm--md {
    padding-bottom: 1.6rem; }
  .spb--md--md {
    padding-bottom: 2.4rem; }
  .spb--lg--md {
    padding-bottom: 3.2rem; }
  .spb--xl--md {
    padding-bottom: 4.8rem; }
  .spb--xxl--md {
    padding-bottom: 6.4rem; }
  .spb--xxxl--md {
    padding-bottom: 8rem; } }

@media (min-width: 1024px) {
  .spb--none--lg {
    padding-bottom: 0; }
  .spb--xs--lg {
    padding-bottom: 0.8rem; }
  .spb--sm--lg {
    padding-bottom: 1.6rem; }
  .spb--md--lg {
    padding-bottom: 2.4rem; }
  .spb--lg--lg {
    padding-bottom: 3.2rem; }
  .spb--xl--lg {
    padding-bottom: 4.8rem; }
  .spb--xxl--lg {
    padding-bottom: 6.4rem; }
  .spb--xxxl--lg {
    padding-bottom: 8rem; } }

@media (min-width: 1280px) {
  .spb--none--xl {
    padding-bottom: 0; }
  .spb--xs--xl {
    padding-bottom: 0.8rem; }
  .spb--sm--xl {
    padding-bottom: 1.6rem; }
  .spb--md--xl {
    padding-bottom: 2.4rem; }
  .spb--lg--xl {
    padding-bottom: 3.2rem; }
  .spb--xl--xl {
    padding-bottom: 4.8rem; }
  .spb--xxl--xl {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xl {
    padding-bottom: 8rem; } }

@media (min-width: 1600px) {
  .spb--none--xxl {
    padding-bottom: 0; }
  .spb--xs--xxl {
    padding-bottom: 0.8rem; }
  .spb--sm--xxl {
    padding-bottom: 1.6rem; }
  .spb--md--xxl {
    padding-bottom: 2.4rem; }
  .spb--lg--xxl {
    padding-bottom: 3.2rem; }
  .spb--xl--xxl {
    padding-bottom: 4.8rem; }
  .spb--xxl--xxl {
    padding-bottom: 6.4rem; }
  .spb--xxxl--xxl {
    padding-bottom: 8rem; } }

.spl--none {
  padding-left: 0; }

.spl--xs {
  padding-left: 0.8rem; }

.spl--sm {
  padding-left: 1.6rem; }

.spl--md {
  padding-left: 2.4rem; }

.spl--lg {
  padding-left: 3.2rem; }

.spl--xl {
  padding-left: 4.8rem; }

.spl--xxl {
  padding-left: 6.4rem; }

.spl--xxxl {
  padding-left: 8rem; }

@media (min-width: 480px) {
  .spl--none--xs {
    padding-left: 0; }
  .spl--xs--xs {
    padding-left: 0.8rem; }
  .spl--sm--xs {
    padding-left: 1.6rem; }
  .spl--md--xs {
    padding-left: 2.4rem; }
  .spl--lg--xs {
    padding-left: 3.2rem; }
  .spl--xl--xs {
    padding-left: 4.8rem; }
  .spl--xxl--xs {
    padding-left: 6.4rem; }
  .spl--xxxl--xs {
    padding-left: 8rem; } }

@media (min-width: 640px) {
  .spl--none--sm {
    padding-left: 0; }
  .spl--xs--sm {
    padding-left: 0.8rem; }
  .spl--sm--sm {
    padding-left: 1.6rem; }
  .spl--md--sm {
    padding-left: 2.4rem; }
  .spl--lg--sm {
    padding-left: 3.2rem; }
  .spl--xl--sm {
    padding-left: 4.8rem; }
  .spl--xxl--sm {
    padding-left: 6.4rem; }
  .spl--xxxl--sm {
    padding-left: 8rem; } }

@media (min-width: 768px) {
  .spl--none--md {
    padding-left: 0; }
  .spl--xs--md {
    padding-left: 0.8rem; }
  .spl--sm--md {
    padding-left: 1.6rem; }
  .spl--md--md {
    padding-left: 2.4rem; }
  .spl--lg--md {
    padding-left: 3.2rem; }
  .spl--xl--md {
    padding-left: 4.8rem; }
  .spl--xxl--md {
    padding-left: 6.4rem; }
  .spl--xxxl--md {
    padding-left: 8rem; } }

@media (min-width: 1024px) {
  .spl--none--lg {
    padding-left: 0; }
  .spl--xs--lg {
    padding-left: 0.8rem; }
  .spl--sm--lg {
    padding-left: 1.6rem; }
  .spl--md--lg {
    padding-left: 2.4rem; }
  .spl--lg--lg {
    padding-left: 3.2rem; }
  .spl--xl--lg {
    padding-left: 4.8rem; }
  .spl--xxl--lg {
    padding-left: 6.4rem; }
  .spl--xxxl--lg {
    padding-left: 8rem; } }

@media (min-width: 1280px) {
  .spl--none--xl {
    padding-left: 0; }
  .spl--xs--xl {
    padding-left: 0.8rem; }
  .spl--sm--xl {
    padding-left: 1.6rem; }
  .spl--md--xl {
    padding-left: 2.4rem; }
  .spl--lg--xl {
    padding-left: 3.2rem; }
  .spl--xl--xl {
    padding-left: 4.8rem; }
  .spl--xxl--xl {
    padding-left: 6.4rem; }
  .spl--xxxl--xl {
    padding-left: 8rem; } }

@media (min-width: 1600px) {
  .spl--none--xxl {
    padding-left: 0; }
  .spl--xs--xxl {
    padding-left: 0.8rem; }
  .spl--sm--xxl {
    padding-left: 1.6rem; }
  .spl--md--xxl {
    padding-left: 2.4rem; }
  .spl--lg--xxl {
    padding-left: 3.2rem; }
  .spl--xl--xxl {
    padding-left: 4.8rem; }
  .spl--xxl--xxl {
    padding-left: 6.4rem; }
  .spl--xxxl--xxl {
    padding-left: 8rem; } }

.spr--none {
  padding-right: 0; }

.spr--xs {
  padding-right: 0.8rem; }

.spr--sm {
  padding-right: 1.6rem; }

.spr--md {
  padding-right: 2.4rem; }

.spr--lg {
  padding-right: 3.2rem; }

.spr--xl {
  padding-right: 4.8rem; }

.spr--xxl {
  padding-right: 6.4rem; }

.spr--xxxl {
  padding-right: 8rem; }

@media (min-width: 480px) {
  .spr--none--xs {
    padding-right: 0; }
  .spr--xs--xs {
    padding-right: 0.8rem; }
  .spr--sm--xs {
    padding-right: 1.6rem; }
  .spr--md--xs {
    padding-right: 2.4rem; }
  .spr--lg--xs {
    padding-right: 3.2rem; }
  .spr--xl--xs {
    padding-right: 4.8rem; }
  .spr--xxl--xs {
    padding-right: 6.4rem; }
  .spr--xxxl--xs {
    padding-right: 8rem; } }

@media (min-width: 640px) {
  .spr--none--sm {
    padding-right: 0; }
  .spr--xs--sm {
    padding-right: 0.8rem; }
  .spr--sm--sm {
    padding-right: 1.6rem; }
  .spr--md--sm {
    padding-right: 2.4rem; }
  .spr--lg--sm {
    padding-right: 3.2rem; }
  .spr--xl--sm {
    padding-right: 4.8rem; }
  .spr--xxl--sm {
    padding-right: 6.4rem; }
  .spr--xxxl--sm {
    padding-right: 8rem; } }

@media (min-width: 768px) {
  .spr--none--md {
    padding-right: 0; }
  .spr--xs--md {
    padding-right: 0.8rem; }
  .spr--sm--md {
    padding-right: 1.6rem; }
  .spr--md--md {
    padding-right: 2.4rem; }
  .spr--lg--md {
    padding-right: 3.2rem; }
  .spr--xl--md {
    padding-right: 4.8rem; }
  .spr--xxl--md {
    padding-right: 6.4rem; }
  .spr--xxxl--md {
    padding-right: 8rem; } }

@media (min-width: 1024px) {
  .spr--none--lg {
    padding-right: 0; }
  .spr--xs--lg {
    padding-right: 0.8rem; }
  .spr--sm--lg {
    padding-right: 1.6rem; }
  .spr--md--lg {
    padding-right: 2.4rem; }
  .spr--lg--lg {
    padding-right: 3.2rem; }
  .spr--xl--lg {
    padding-right: 4.8rem; }
  .spr--xxl--lg {
    padding-right: 6.4rem; }
  .spr--xxxl--lg {
    padding-right: 8rem; } }

@media (min-width: 1280px) {
  .spr--none--xl {
    padding-right: 0; }
  .spr--xs--xl {
    padding-right: 0.8rem; }
  .spr--sm--xl {
    padding-right: 1.6rem; }
  .spr--md--xl {
    padding-right: 2.4rem; }
  .spr--lg--xl {
    padding-right: 3.2rem; }
  .spr--xl--xl {
    padding-right: 4.8rem; }
  .spr--xxl--xl {
    padding-right: 6.4rem; }
  .spr--xxxl--xl {
    padding-right: 8rem; } }

@media (min-width: 1600px) {
  .spr--none--xxl {
    padding-right: 0; }
  .spr--xs--xxl {
    padding-right: 0.8rem; }
  .spr--sm--xxl {
    padding-right: 1.6rem; }
  .spr--md--xxl {
    padding-right: 2.4rem; }
  .spr--lg--xxl {
    padding-right: 3.2rem; }
  .spr--xl--xxl {
    padding-right: 4.8rem; }
  .spr--xxl--xxl {
    padding-right: 6.4rem; }
  .spr--xxxl--xxl {
    padding-right: 8rem; } }

.sp--none {
  padding: 0; }

.sp--xs {
  padding: 0.8rem; }

.sp--sm {
  padding: 1.6rem; }

.sp--md {
  padding: 2.4rem; }

.sp--lg {
  padding: 3.2rem; }

.sp--xl {
  padding: 4.8rem; }

.sp--xxl {
  padding: 6.4rem; }

.sp--xxxl {
  padding: 8rem; }

@media (min-width: 480px) {
  .sp--none--xs {
    padding: 0; }
  .sp--xs--xs {
    padding: 0.8rem; }
  .sp--sm--xs {
    padding: 1.6rem; }
  .sp--md--xs {
    padding: 2.4rem; }
  .sp--lg--xs {
    padding: 3.2rem; }
  .sp--xl--xs {
    padding: 4.8rem; }
  .sp--xxl--xs {
    padding: 6.4rem; }
  .sp--xxxl--xs {
    padding: 8rem; } }

@media (min-width: 640px) {
  .sp--none--sm {
    padding: 0; }
  .sp--xs--sm {
    padding: 0.8rem; }
  .sp--sm--sm {
    padding: 1.6rem; }
  .sp--md--sm {
    padding: 2.4rem; }
  .sp--lg--sm {
    padding: 3.2rem; }
  .sp--xl--sm {
    padding: 4.8rem; }
  .sp--xxl--sm {
    padding: 6.4rem; }
  .sp--xxxl--sm {
    padding: 8rem; } }

@media (min-width: 768px) {
  .sp--none--md {
    padding: 0; }
  .sp--xs--md {
    padding: 0.8rem; }
  .sp--sm--md {
    padding: 1.6rem; }
  .sp--md--md {
    padding: 2.4rem; }
  .sp--lg--md {
    padding: 3.2rem; }
  .sp--xl--md {
    padding: 4.8rem; }
  .sp--xxl--md {
    padding: 6.4rem; }
  .sp--xxxl--md {
    padding: 8rem; } }

@media (min-width: 1024px) {
  .sp--none--lg {
    padding: 0; }
  .sp--xs--lg {
    padding: 0.8rem; }
  .sp--sm--lg {
    padding: 1.6rem; }
  .sp--md--lg {
    padding: 2.4rem; }
  .sp--lg--lg {
    padding: 3.2rem; }
  .sp--xl--lg {
    padding: 4.8rem; }
  .sp--xxl--lg {
    padding: 6.4rem; }
  .sp--xxxl--lg {
    padding: 8rem; } }

@media (min-width: 1280px) {
  .sp--none--xl {
    padding: 0; }
  .sp--xs--xl {
    padding: 0.8rem; }
  .sp--sm--xl {
    padding: 1.6rem; }
  .sp--md--xl {
    padding: 2.4rem; }
  .sp--lg--xl {
    padding: 3.2rem; }
  .sp--xl--xl {
    padding: 4.8rem; }
  .sp--xxl--xl {
    padding: 6.4rem; }
  .sp--xxxl--xl {
    padding: 8rem; } }

@media (min-width: 1600px) {
  .sp--none--xxl {
    padding: 0; }
  .sp--xs--xxl {
    padding: 0.8rem; }
  .sp--sm--xxl {
    padding: 1.6rem; }
  .sp--md--xxl {
    padding: 2.4rem; }
  .sp--lg--xxl {
    padding: 3.2rem; }
  .sp--xl--xxl {
    padding: 4.8rem; }
  .sp--xxl--xxl {
    padding: 6.4rem; }
  .sp--xxxl--xxl {
    padding: 8rem; } }

.spx--none {
  padding-left: 0;
  padding-right: 0; }

.spx--xs {
  padding-left: 0.8rem;
  padding-right: 0.8rem; }

.spx--sm {
  padding-left: 1.6rem;
  padding-right: 1.6rem; }

.spx--md {
  padding-left: 2.4rem;
  padding-right: 2.4rem; }

.spx--lg {
  padding-left: 3.2rem;
  padding-right: 3.2rem; }

.spx--xl {
  padding-left: 4.8rem;
  padding-right: 4.8rem; }

.spx--xxl {
  padding-left: 6.4rem;
  padding-right: 6.4rem; }

.spx--xxxl {
  padding-left: 8rem;
  padding-right: 8rem; }

@media (min-width: 480px) {
  .spx--none--xs {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xs {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xs {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xs {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xs {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xs {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xs {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xs {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 640px) {
  .spx--none--sm {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--sm {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--sm {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--sm {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--sm {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--sm {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--sm {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--sm {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 768px) {
  .spx--none--md {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--md {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--md {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--md {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--md {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--md {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--md {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--md {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1024px) {
  .spx--none--lg {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--lg {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--lg {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--lg {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--lg {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--lg {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--lg {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--lg {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1280px) {
  .spx--none--xl {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xl {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xl {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xl {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xl {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xl {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xl {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xl {
    padding-left: 8rem;
    padding-right: 8rem; } }

@media (min-width: 1600px) {
  .spx--none--xxl {
    padding-left: 0;
    padding-right: 0; }
  .spx--xs--xxl {
    padding-left: 0.8rem;
    padding-right: 0.8rem; }
  .spx--sm--xxl {
    padding-left: 1.6rem;
    padding-right: 1.6rem; }
  .spx--md--xxl {
    padding-left: 2.4rem;
    padding-right: 2.4rem; }
  .spx--lg--xxl {
    padding-left: 3.2rem;
    padding-right: 3.2rem; }
  .spx--xl--xxl {
    padding-left: 4.8rem;
    padding-right: 4.8rem; }
  .spx--xxl--xxl {
    padding-left: 6.4rem;
    padding-right: 6.4rem; }
  .spx--xxxl--xxl {
    padding-left: 8rem;
    padding-right: 8rem; } }

.spy--none {
  padding-top: 0;
  padding-bottom: 0; }

.spy--xs {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem; }

.spy--sm {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem; }

.spy--md {
  padding-top: 2.4rem;
  padding-bottom: 2.4rem; }

.spy--lg {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem; }

.spy--xl {
  padding-top: 4.8rem;
  padding-bottom: 4.8rem; }

.spy--xxl {
  padding-top: 6.4rem;
  padding-bottom: 6.4rem; }

.spy--xxxl {
  padding-top: 8rem;
  padding-bottom: 8rem; }

@media (min-width: 480px) {
  .spy--none--xs {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xs {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xs {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xs {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xs {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xs {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xs {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xs {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 640px) {
  .spy--none--sm {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--sm {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--sm {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--sm {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--sm {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--sm {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--sm {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--sm {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 768px) {
  .spy--none--md {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--md {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--md {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--md {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--md {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--md {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--md {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--md {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1024px) {
  .spy--none--lg {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--lg {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--lg {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--lg {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--lg {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--lg {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--lg {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--lg {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1280px) {
  .spy--none--xl {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xl {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

@media (min-width: 1600px) {
  .spy--none--xxl {
    padding-top: 0;
    padding-bottom: 0; }
  .spy--xs--xxl {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem; }
  .spy--sm--xxl {
    padding-top: 1.6rem;
    padding-bottom: 1.6rem; }
  .spy--md--xxl {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem; }
  .spy--lg--xxl {
    padding-top: 3.2rem;
    padding-bottom: 3.2rem; }
  .spy--xl--xxl {
    padding-top: 4.8rem;
    padding-bottom: 4.8rem; }
  .spy--xxl--xxl {
    padding-top: 6.4rem;
    padding-bottom: 6.4rem; }
  .spy--xxxl--xxl {
    padding-top: 8rem;
    padding-bottom: 8rem; } }

.button {
  flex: 0 0 auto;
  display: inline-flex;
  align-items: center;
  border: 1px solid transparent;
  border-radius: .5rem;
  padding: .8rem 1.6rem;
  background-color: transparent;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  color: #2c3d5f;
  transition: background-color .2s ease; }
  .button[disabled], .button.is-disabled {
    background-color: #ddd !important;
    border-color: #ddd !important;
    color: #fff !important;
    box-shadow: none !important; }
    .button[disabled]:hover, .button.is-disabled:hover {
      background-color: #ddd !important;
      border-color: #ddd !important;
      color: #fff !important;
      box-shadow: none !important;
      cursor: default; }
  @media (min-width: 1024px) {
    .button {
      font-size: 1.6rem;
      padding: 1.6rem 2rem; } }
  .button:hover {
    cursor: pointer; }
  .button--primary {
    background-color: #628ad9;
    color: #fff;
    box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.2); }
    .button--primary:hover {
      background-color: #8ba9e3; }
  .button--secondary {
    background-color: #2c3d5f;
    color: #fff; }
    .button--secondary:hover {
      background-color: #3c5382; }
  .button--tertiary {
    position: relative;
    z-index: 0;
    background-color: #b61615;
    color: #fff; }
    .button--tertiary:after {
      content: '';
      display: block;
      background-image: linear-gradient(to right, transparent, #6b0a09);
      position: absolute;
      top: -1px;
      bottom: -1px;
      left: -1px;
      right: -1px;
      z-index: -1;
      border-radius: .5rem; }
    .button--tertiary:hover {
      background-color: #6b0a09; }
  .button--quaternary {
    background-color: #fff;
    color: #2c3d5f; }
  .button--ghost {
    border-color: #ddd;
    background-color: #fff; }
    .button--ghost:hover, .sector:hover .button--ghost, .blog:hover .button--ghost {
      background-color: #f3f3f3; }
  .button--ghost-inverted {
    border-color: #fff;
    color: #fff; }
    .button--ghost-inverted:hover {
      background-color: #fff;
      color: #2c3d5f; }
  .button--square {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4.8rem;
    height: 4.8rem;
    padding: 0; }

.button__icon {
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem; }
  .button__icon--left {
    margin-right: .8rem; }
  .button__icon--ghost {
    fill: #2c3d5f; }
  .button__icon--ghost-inverted {
    fill: #fff; }
    .button:hover .button__icon--ghost-inverted {
      fill: #2c3d5f; }

.form .umbraco-forms-field {
  margin-top: 2rem; }
  .form .umbraco-forms-field:first-child {
    margin-top: 0; }

.form .grid {
  margin: 0 -1rem; }

.form [class*="grid-item"] {
  padding: 0 1rem; }
  .form [class*="grid-item"] + [class*="grid-item"] {
    margin-top: 2rem; }
    @media (min-width: 1024px) {
      .form [class*="grid-item"] + [class*="grid-item"] {
        margin-top: 0; } }

.form__field-wrapper {
  width: 100%;
  padding-top: .8rem; }

.form__header {
  margin-bottom: 3.2rem; }

.form__footer {
  margin-top: 3.2rem; }

.form__group + .form__group {
  margin-top: 2rem; }

.form__label {
  display: block;
  font-weight: 500; }
  .fileupload .form__label {
    flex: 0 0 auto;
    padding-right: 1.6rem;
    padding-bottom: 0; }
  .form__label.is-visible {
    display: block; }

.form__tooltip {
  display: block;
  font-size: 1.2rem;
  color: #bbb; }

.form__input {
  display: block;
  width: 100%;
  border: 0;
  outline: 0;
  background-color: #f3f3f3;
  padding: 1.2rem 1.6rem;
  border-radius: 0.5rem;
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem; }
  .form__input--textarea {
    min-height: 15rem; }

.toggle {
  display: inline-flex;
  align-items: flex-start; }
  .toggle:hover {
    cursor: pointer; }
  .toggle__input {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  .toggle__switch {
    flex: 0 0 auto;
    display: inline-flex;
    background-color: #ddd;
    border-radius: .5rem;
    position: relative;
    margin-right: 1.2rem; }
    .toggle__switch:after {
      content: '';
      width: 3.2rem;
      height: 3.2rem;
      display: block;
      position: absolute;
      z-index: 0;
      top: 0;
      left: 0;
      background-color: #b61615;
      border-radius: .5rem;
      transition: transform .2s ease, background-color .2s ease; }
    :checked + .toggle__switch:after {
      background-color: #13b67a;
      transform: translateX(3.2rem); }
  .toggle__switch-icon {
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff;
    display: block;
    margin: .8rem;
    position: relative;
    z-index: 1; }
    .toggle__switch-icon--times {
      padding: .2rem; }
  .toggle__label {
    margin-top: .4rem; }

.card {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
  padding: 2.4rem; }
  @media (min-width: 1024px) {
    .card {
      padding: 3.6rem; } }
  .card__title {
    margin-bottom: 2rem; }

.card__header {
  display: flex; }

.card__indicator {
  margin-left: auto;
  padding-left: 1.6rem;
  font-weight: 500;
  color: #bbb; }

.paragraph {
  width: 100%;
  padding-top: 3.6rem; }
  .paragraph:last-child {
    padding-bottom: 3.6rem; }
  @media (min-width: 640px) {
    .paragraph.call-to-action:last-child {
      padding-bottom: 7.2rem; } }
  @media (min-width: 1024px) {
    .paragraph {
      padding-top: 6.4rem; }
      .paragraph:last-child {
        padding-bottom: 6.4rem; }
      .paragraph.call-to-action:last-child {
        padding-bottom: 12.8rem; } }

.timeline-w-image,
.timeline-w-brands {
  position: relative; }
  .timeline-w-image:after,
  .timeline-w-brands:after {
    content: "";
    display: block;
    width: 2px;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #6b0a09, #6b0a09 50%, #fff 50%, #fff);
    background-size: 100% 20px;
    position: absolute;
    top: 50%;
    left: calc(16px + 3rem);
    bottom: -3.6rem; }
    @media (min-width: 640px) {
      .timeline-w-image:after,
      .timeline-w-brands:after {
        left: 50%;
        transform: translateX(-50%);
        bottom: -6.4rem; } }
  .timeline-w-image + .timeline-w-image:before,
  .timeline-w-brands + .timeline-w-image:before, .timeline-w-image +
  .timeline-w-brands:before,
  .timeline-w-brands +
  .timeline-w-brands:before {
    content: "";
    display: block;
    width: 2px;
    background-color: transparent;
    background-image: linear-gradient(to bottom, #6b0a09, #6b0a09 50%, #fff 50%, #fff);
    background-size: 100% 20px;
    position: absolute;
    top: 0;
    bottom: 50%;
    left: calc(16px + 3rem); }
    @media (min-width: 640px) {
      .timeline-w-image + .timeline-w-image:before,
      .timeline-w-brands + .timeline-w-image:before, .timeline-w-image +
      .timeline-w-brands:before,
      .timeline-w-brands +
      .timeline-w-brands:before {
        left: 50%;
        transform: translateX(-50%); } }
  .timeline-w-image.is-last-timeline-item:after,
  .timeline-w-brands.is-last-timeline-item:after {
    content: none; }
  @media (min-width: 640px) {
    .timeline-w-image--left .grid,
    .timeline-w-brands--left .grid {
      flex-direction: row-reverse; } }
  .timeline-w-image .grid,
  .timeline-w-brands .grid {
    align-items: center; }
  .timeline-w-image [class*="grid-item"]:last-child,
  .timeline-w-brands [class*="grid-item"]:last-child {
    display: none; }
    @media (min-width: 640px) {
      .timeline-w-image [class*="grid-item"]:last-child,
      .timeline-w-brands [class*="grid-item"]:last-child {
        display: block; } }
  .timeline-w-image__body,
  .timeline-w-brands__body {
    margin-left: 7.6rem; }
    @media (min-width: 640px) {
      .timeline-w-image__body,
      .timeline-w-brands__body {
        margin-left: 0; } }
  .timeline-w-image__icon,
  .timeline-w-brands__icon {
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    background-color: #6b0a09;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 16px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 640px) {
      .timeline-w-image__icon,
      .timeline-w-brands__icon {
        width: 8rem;
        height: 8rem;
        left: 50%;
        transform: translate(-50%, -50%); }
        .timeline-w-image__icon img,
        .timeline-w-brands__icon img {
          width: 4rem;
          height: 4rem; } }
    .timeline-w-image__icon img,
    .timeline-w-brands__icon img {
      flex: 0 0 auto;
      display: block;
      width: 3rem;
      height: 3rem;
      fill: #fff; }

.timeline-w-image__image {
  display: block;
  width: 64rem;
  max-width: 100%;
  margin: 0 auto; }
  .timeline-w-image__image--rounded {
    border-radius: 50%;
    width: 48rem; }

.timeline-w-brands__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0 -2rem; }
  .timeline-w-brands__list li {
    padding: 1rem 2rem; }

.timeline-stop {
  position: relative; }
  .timeline-stop__body {
    max-width: 48rem;
    margin: 0 auto;
    padding-top: 9.2rem; }
    @media (min-width: 640px) {
      .timeline-stop__body {
        text-align: center;
        padding-top: 12.8rem; } }
  .timeline-stop__icon {
    width: 6rem;
    height: 6rem;
    border-radius: 4rem;
    background-color: #6b0a09;
    position: absolute;
    z-index: 1;
    top: 3.6rem;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center; }
    @media (min-width: 640px) {
      .timeline-stop__icon {
        width: 8rem;
        height: 8rem;
        left: 50%;
        transform: translateX(-50%); }
        .timeline-stop__icon img {
          width: 4rem;
          height: 4rem; } }
    .timeline-stop__icon img {
      flex: 0 0 auto;
      display: block;
      width: 3rem;
      height: 3rem;
      fill: #fff; }

.quote {
  position: relative;
  width: 100%; }
  .quote .grid {
    align-items: center; }
  .quote__quote {
    width: 4.4rem;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border-radius: 0.5rem;
    background-color: #ddd;
    font-weight: bold;
    color: #fff; }
  .quote__quote-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff; }

.simple-quote__body {
  padding: 3.6rem;
  background-color: #2c3d5f;
  color: #fff; }
  @media (min-width: 1024px) {
    .simple-quote__body {
      padding: 4.8rem; } }

.teammember__image {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin: 2rem 0; }

.teammember__body {
  text-align: center; }

.teammember__function {
  font-weight: 500;
  color: #b61615; }

.teammember__prop {
  margin-top: 0.8rem; }

.teammember__prop-icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.6rem;
  height: 1.6rem;
  fill: #6b0a09;
  margin-right: 0.8rem; }

@media (min-width: 640px) {
  .call-to-action {
    padding-top: 12.8rem;
    padding-bottom: 6.4rem; } }

.call-to-action__wrapper {
  position: relative; }

.call-to-action__body {
  background-color: #2c3d5f;
  color: #fff;
  padding: 3.6rem; }
  @media (min-width: 640px) {
    .call-to-action__body {
      padding: 4.8rem 50% 4.8rem 4.8rem; } }

.call-to-action__image {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0; }
  .call-to-action__image:after {
    content: "";
    display: block;
    width: 100%;
    height: 50%;
    background-color: #2c3d5f;
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0; }
    @media (min-width: 640px) {
      .call-to-action__image:after {
        content: none; } }
  @media (min-width: 640px) {
    .call-to-action__image {
      position: absolute;
      top: -3.6rem;
      bottom: -3.6rem;
      right: 0;
      width: 50%; } }
  @media (min-width: 1024px) {
    .call-to-action__image {
      top: -6.4rem;
      bottom: -6.4rem; } }
  .call-to-action__image img {
    width: 50%;
    height: 100%;
    object-fit: contain; }
    @media (min-width: 640px) {
      .call-to-action__image img {
        width: 100%; } }

.text-w-image--left .grid {
  flex-direction: row-reverse; }

.text-w-image__image {
  display: block;
  width: 64rem;
  max-width: 100%;
  margin: 0 auto; }
  .text-w-image__image--rounded {
    width: 32rem;
    border-radius: 50%; }

.flow {
  padding: 1.6rem; }
  @media (min-width: 1024px) {
    .flow {
      min-height: 75vh; } }
  .flow__question {
    display: block;
    font-size: 1.6rem;
    margin-bottom: .8em; }
  .flow__answer {
    transition: opacity .25s ease; }
    .flow__answer.is-disabled {
      opacity: .5; }
    .flow__answer + .flow__answer {
      margin-top: .8rem; }
    .flow__answer input {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px; }
      .flow__answer input:checked + label {
        background-color: rgba(98, 138, 217, 0.075);
        border-color: #628ad9; }
    .flow__answer label {
      display: block;
      font-weight: 500;
      line-height: 1.33;
      border: 2px solid #ddd;
      padding: 1.2rem 1.4rem;
      border-radius: .5rem; }
      .flow__answer label:hover {
        cursor: pointer; }
  .flow__reply {
    display: flex;
    font-size: 1.5rem;
    font-weight: 500;
    background-color: rgba(19, 182, 122, 0.075);
    padding: 1.6rem;
    border-radius: .4rem; }
  .flow__reply-icon {
    flex: 0 0 auto;
    display: block;
    width: 1.8rem;
    height: 1.8rem;
    fill: #13b67a;
    margin-top: .2rem;
    margin-right: 1.6rem; }

.flow__list-item {
  width: 100%;
  max-width: 72rem;
  margin: 1.6rem auto 0; }
  .flow__list-item:first-child {
    margin-top: 0; }
  .flow__list-item.is-hidden {
    display: none; }

.cookiebanner {
  position: fixed;
  z-index: 99;
  bottom: 24px;
  left: 24px;
  width: 640px;
  height: auto;
  max-width: calc(100% - 48px);
  clip: auto;
  clip-path: none;
  overflow: visible;
  white-space: normal; }
  .cookiebanner.is-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px; }
  .cookiebanner:before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background-image: radial-gradient(circle at bottom left, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 50%);
    background-size: cover;
    background-repeat: no-repeat;
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0; }

.cookiebanner__body {
  position: relative;
  z-index: 0;
  opacity: 1;
  transform: translate3d(0, 0, 0);
  transition: opacity 1s ease, transform 1s ease; }
  .cookiebanner.is-hidden .cookiebanner__body {
    opacity: 0;
    transform: translate3d(0, 24px, 0); }
  .cookiebanner__body.is-hidden {
    display: none; }

.cookiebanner__options {
  margin-top: 16px; }

.cookiebanner__actions {
  margin-top: 24px; }
  .cookiebanner__actions ul {
    width: 100%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none; }
  .cookiebanner__actions li + li {
    margin-left: 16px; }

.switch__input {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px; }
  .switch__input:checked + .switch__field .switch__symbol {
    background-color: #1fd68a; }
    .switch__input:checked + .switch__field .switch__symbol:before {
      transform: translateX(20px); }

.switch__field {
  display: flex;
  align-items: center; }
  .switch__field:hover {
    cursor: pointer; }

.switch__symbol {
  position: relative;
  display: block;
  width: 48px;
  height: 28px;
  background-color: #eee;
  border-radius: 14px;
  margin-right: 16px;
  transition: background-color .2s ease; }
  .switch__symbol:before {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 4px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    transition: transform .2s ease; }

.switch__label {
  font-weight: 500;
  line-height: 1.5; }
  .switch__label small {
    display: block;
    font-weight: normal;
    color: #999; }

.main {
  width: 100%; }

.container {
  display: block;
  width: 100%;
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 24px; }
  @media (min-width: 1024px) {
    .container {
      padding: 0 24px; } }
  .container--large {
    max-width: 1200px; }
  .container--medium {
    max-width: 960px; }
  .container--small {
    max-width: 800px; }

.section {
  padding: 3.2rem 0; }
  @media (min-width: 640px) {
    .section {
      padding: 6.4rem 0; } }

.bg--sun {
  background-color: #b61615;
  background-image: linear-gradient(to right, #b61615, #6b0a09);
  color: #fff; }

.bg--azure {
  background-color: #f3f6Fc; }

.pagetitle {
  text-align: center; }

.rtxt h1:last-child,
.rtxt h2:last-child,
.rtxt h3:last-child,
.rtxt h4:last-child,
.rtxt h5:last-child,
.rtxt h6:last-child,
.rtxt p:last-child,
.rtxt ol:last-child,
.rtxt ul:last-child {
  margin-bottom: 0; }

.rtxt h1,
.rtxt h2,
.rtxt h3,
.rtxt h4,
.rtxt h5,
.rtxt h6 {
  margin-bottom: 0.8em; }
  .rtxt h1 small,
  .rtxt h2 small,
  .rtxt h3 small,
  .rtxt h4 small,
  .rtxt h5 small,
  .rtxt h6 small {
    font-size: 66.66%;
    color: #b61615; }

.rtxt *:not(.subtitle) + h1,
.rtxt *:not(.subtitle) + h2,
.rtxt *:not(.subtitle) + h3,
.rtxt *:not(.subtitle) + h4,
.rtxt *:not(.subtitle) + h5,
.rtxt *:not(.subtitle) + h6 {
  margin-top: 1.2em; }

.rtxt p,
.rtxt ol,
.rtxt ul {
  margin-bottom: 1.2em; }

.rtxt ol,
.rtxt ul {
  padding-left: 1em; }

.rtxt ol {
  list-style: decimal; }

.rtxt ul {
  list-style: disc; }

.rtxt li {
  padding-left: 0.4rem; }
  .rtxt li + li {
    margin-top: 0.8rem; }

.rtxt a:not([class]) {
  text-decoration: underline; }

.address {
  font-style: normal; }
  .address h4 {
    margin-bottom: 0.8em; }
  .address li {
    display: flex;
    align-items: center; }
    .address li + li {
      margin-top: 0.8rem; }
  .address__icon {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #2c3d5f;
    margin-right: 0.8rem; }

.footer .address__icon {
  fill: #fff; }

.intro {
  position: relative;
  z-index: 0;
  width: 100%;
  display: flex;
  align-items: center; }
  @media (min-width: 1024px) {
    .intro {
      min-height: 75vh; } }
  .scan .intro__body {
    display: none; }
    @media (min-width: 1024px) {
      .scan .intro__body {
        display: block; } }
  @media (min-width: 1024px) {
    .intro__body {
      position: relative; } }
  .intro__arrow {
    display: none; }
    @media (min-width: 1024px) {
      .intro__arrow {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 12rem; } }
  .intro .grid {
    align-items: center; }

@media (min-width: 1024px) {
  .intro-figure:after {
    content: '';
    display: block;
    width: 50%;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: -6.4rem;
    background-image: url(../images/bg_logo.svg);
    background-size: contain;
    background-position: top right;
    background-repeat: no-repeat; } }

.steps {
  width: 100%; }
  .steps__footer {
    text-align: center; }

.step__indicator {
  width: 4.4rem;
  height: 4.4rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: #2c3d5f;
  font-weight: bold;
  color: #fff; }

.testimonials {
  width: 100%; }
  .testimonials .subtitle {
    color: inherit; }
  .testimonials__slider {
    position: relative;
    padding-bottom: 6rem; }
  .testimonials__slider-slides {
    margin: 0 -1rem; }
  .testimonials__slider-slide {
    padding: 0 1rem;
    outline: 0; }
  .testimonials__slider-button {
    position: absolute;
    z-index: 1;
    bottom: 0;
    width: 4.4rem;
    height: 4.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2.2rem;
    outline: 0;
    border: 1px solid rgba(44, 61, 95, 0.15);
    background-color: transparent;
    transition: all 0.2s ease; }
    .bg--sun .testimonials__slider-button {
      border-color: rgba(255, 255, 255, 0.15); }
    .testimonials__slider-button.is-hidden {
      display: none; }
    .testimonials__slider-button:hover {
      background-color: #f3f3f3;
      border-color: #f3f3f3;
      cursor: pointer; }
      .testimonials__slider-button:hover .testimonials__slider-button-icon {
        fill: #628ad9; }
      .bg--sun .testimonials__slider-button:hover .testimonials__slider-button-icon {
        fill: #2c3d5f; }
    .testimonials__slider-button--next {
      right: 16px; }
    .testimonials__slider-button--prev {
      left: 16px; }
  .testimonials__slider-button-icon {
    flex: 0 0 auto;
    width: 1.6rem;
    height: 1.6rem;
    display: block;
    fill: #2c3d5f;
    transition: all 0.2s ease; }
    .bg--sun .testimonials__slider-button-icon {
      fill: #fff; }

.testimonial {
  position: relative;
  margin-top: 2.2rem;
  margin-bottom: 1.6rem;
  padding: 2.4rem;
  border-radius: 0.5rem;
  background-color: #fff;
  color: #2c3d5f;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1); }
  .testimonial__image {
    max-width: 50%;
    margin: 0 auto 2.4rem; }
    .testimonial__image img {
      border-radius: 50%; }
  .testimonial__quote {
    position: absolute;
    top: -2.2rem;
    left: 2.4rem;
    width: 4.4rem;
    height: 4.4rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0.8rem;
    border-radius: 0.5rem;
    background-color: #2c3d5f;
    font-weight: bold;
    color: #fff; }
  .testimonial__quote-icon {
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #fff; }
  .testimonial__score-label {
    font-size: 1.2rem; }
  .testimonial__score-number {
    display: flex;
    align-items: center;
    font-weight: bold;
    color: #13b67a; }
  .testimonial__score-stars {
    display: flex;
    align-items: center;
    margin-left: 0.8rem; }
  .testimonial__score-star-wrapper {
    position: relative; }
    .testimonial__score-star-wrapper + .testimonial__score-star-wrapper {
      margin-left: 0.4rem; }
  .testimonial__score-star {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 2rem;
    height: 2rem;
    fill: #13b67a; }
  .testimonial__score-star-background {
    display: block;
    width: 2rem;
    height: 2rem;
    fill: #f3f3f3; }

@media (min-width: 640px) {
  .sectors__title {
    grid-column: 1 / span 2; } }

.blog + .sectors__title {
  margin-top: 6.4rem; }

.sectors__grid--overview {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%; }
  @media (min-width: 1600px) {
    .sectors__grid--overview {
      grid-template-columns: 1fr 1fr; } }

.sector {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  border-radius: .5rem;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  @media (min-width: 640px) {
    .sector {
      grid-template-columns: minmax(min-content, 320px) 1fr; } }
  .sector__title {
    margin-top: 0 !important; }
  .sector__image {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    padding-bottom: 56.25%;
    height: 0;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem; }
    @media (min-width: 640px) {
      .sector__image {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.5rem; } }
  .sector__body {
    display: block;
    width: 100%;
    padding: 2.4rem; }
  .sector__read-more {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    text-decoration: underline;
    transition: color .2s ease; }
  .sector__read-more-icon {
    flex: 0 0 auto;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #2c3d5f;
    margin-left: .8rem;
    transition: fill .2s ease; }

@media (min-width: 640px) {
  .blogs__title {
    grid-column: 1 / span 2; } }

.blog + .blogs__title {
  margin-top: 6.4rem; }

.blogs__grid--overview {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  width: 100%; }
  @media (min-width: 640px) {
    .blogs__grid--overview {
      grid-template-columns: 1fr 1fr; } }

.blog {
  display: block;
  width: 100%;
  border-radius: .5rem;
  box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
  background-color: #fff; }
  @media (min-width: 640px) {
    .blog--large {
      grid-column: 1 / span 2; } }
  @media (min-width: 1024px) {
    .blog--large {
      grid-row: 2 / span 2;
      grid-column: auto; } }
  .blog--small {
    display: flex;
    flex-wrap: wrap; }
    @media (min-width: 1024px) {
      .blog--small {
        flex-wrap: nowrap; } }
    .blog--small + .recent-blog--small {
      margin-top: 4rem; }
  .blog__title {
    margin-top: 0 !important; }
  .blog__date {
    display: flex;
    align-items: center;
    font-weight: 500;
    color: #bbb;
    margin-bottom: 1.2rem !important; }
  .blog__date-icon {
    display: block;
    fill: #bbb;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: .8rem; }
  .blog__image {
    flex: 0 0 auto;
    display: block;
    width: 100%;
    padding-bottom: 50%;
    height: 0;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem; }
    @media (min-width: 1024px) {
      [class*="small"] .blog__image {
        width: 50%;
        height: 100%;
        padding-bottom: 0;
        border-top-right-radius: 0;
        border-bottom-left-radius: .5rem; } }
  .blog__body {
    display: block;
    width: 100%;
    padding: 2.4rem; }
  .blog__read-more {
    display: inline-flex;
    align-items: center;
    font-weight: 500;
    text-decoration: underline;
    transition: color .2s ease; }
    .recent-blog:hover .blog__read-more {
      text-decoration: none;
      color: #b61615; }
  .blog__read-more-icon {
    flex: 0 0 auto;
    display: block;
    width: 1.6rem;
    height: 1.6rem;
    fill: #2c3d5f;
    margin-left: .8rem;
    transition: fill .2s ease; }
    .recent-blog:hover .blog__read-more-icon {
      fill: #b61615; }

.scan__pagetitle {
  margin-bottom: 6.4rem; }

.question + .question {
  margin-top: 1.6rem; }

.suggestion {
  margin-top: 3.2rem;
  padding-left: 7.6rem; }

.widget--timeline {
  max-width: 20rem;
  margin: 0 auto; }

.contact {
  padding: 6.4rem 0; }
  .contact__form {
    background-color: #fff;
    border-radius: .5rem;
    box-shadow: 0 0.4rem 1.6rem rgba(0, 0, 0, 0.1);
    padding: 2.4rem; }
    @media (min-width: 1024px) {
      .contact__form {
        padding: 4.8rem; } }
  .contact__header {
    margin-bottom: 3.2rem; }
  .contact__footer {
    margin-top: 3.2rem; }
  .contact .address + .address {
    margin-top: 3.2rem; }

.map {
  width: 100%;
  height: 24rem;
  background-color: #ddd;
  position: relative; }
  @media (min-width: 1024px) {
    .map {
      height: 48rem; } }
  .map iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    border: 0; }

.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: #fff; }
  @media (min-width: 1024px) {
    .header {
      position: static;
      top: auto;
      left: auto;
      z-index: 0; } }
  .header__top {
    display: none;
    background-color: #2c3d5f;
    color: #fff; }
    @media (min-width: 1024px) {
      .header__top {
        display: block; } }
    .header__top .container {
      display: flex;
      align-items: center; }
  .header__bottom {
    border-bottom: 1px solid #ddd; }
    .header__bottom .container {
      display: flex;
      align-items: stretch; }
  .header__phone-numbers {
    font-style: normal; }
    .header__phone-numbers ul {
      display: flex;
      align-items: center; }
  .header__logo {
    margin: 1.2rem auto 1.2rem 0; }
    @media (min-width: 1024px) {
      .header__logo {
        margin: 1.6rem auto 1.6rem 0; } }

.logo {
  flex: 0 0 auto;
  display: inline-flex;
  justify-self: center;
  align-items: center; }
  .logo__image {
    width: 8rem; }
    @media (min-width: 1024px) {
      .logo__image {
        width: 16rem; } }

.nav--main {
  margin: 0 auto;
  display: none; }
  @media (min-width: 1024px) {
    .nav--main {
      display: block; } }
  .nav--main > ul {
    display: flex;
    align-items: stretch;
    height: 100%; }
  .nav--main > ul > li {
    position: relative;
    display: flex;
    align-items: center; }
    .nav--main > ul > li.is-active:after {
      content: "";
      display: block;
      width: 100%;
      height: 0.3rem;
      background-color: #b61615;
      position: absolute;
      bottom: 0; }
    .nav--main > ul > li:hover > ul {
      clip: auto;
      clip-path: none;
      overflow: visible;
      white-space: normal;
      width: auto;
      height: auto; }
  .nav--main > ul > li > a {
    display: inline-block;
    padding: 0.8rem 1.2rem;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    color: #575e71;
    transition: color 0.2s ease; }
    .nav--main > ul > li > a:hover {
      color: #b61615; }
  .nav--main > ul > li > .nav__sub-toggle {
    display: none; }
  .nav--main > ul > li > ul {
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    white-space: wrap;
    width: 1px;
    height: 1px;
    z-index: 99;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border: 1px solid #ddd; }
  .nav--main > ul > li > ul > li {
    border-top: 1px solid #ddd; }
    .nav--main > ul > li > ul > li:first-child {
      border-top: 0 none; }
  .nav--main > ul > li > ul > li > a {
    display: block;
    padding: 8px 16px;
    font-size: 90%;
    font-weight: 600; }
    .nav--main > ul > li > ul > li > a:hover {
      background-color: #f7f7f7;
      text-decoration: underline; }

.nav--sub {
  margin-left: auto; }
  .nav--sub > ul {
    display: flex;
    align-items: center; }
  .nav--sub > ul > li {
    border-left: 1px solid #fff; }
    .nav--sub > ul > li:last-child {
      border-right: 1px solid #fff; }
  .nav--sub > ul > li > a {
    display: inline-block;
    padding: 0.8rem 1.6rem;
    font-size: 1.4rem;
    transition: background-color 0.2s ease; }
    .nav--sub > ul > li > a:hover {
      background-color: #3c5382; }

.nav--client {
  display: none;
  align-items: center;
  margin-left: auto; }
  @media (min-width: 1024px) {
    .nav--client {
      display: flex; } }
  .nav--client ul {
    display: flex;
    align-items: center; }
  .nav--client li + li {
    margin-left: 1.6rem; }

.phone-number__link {
  display: inline-flex;
  align-items: center;
  padding: 0.8rem; }

.phone-number__icon {
  flex: 0 0 auto;
  display: inline-block;
  width: 1.6rem;
  height: 1.6rem;
  margin-right: 0.8rem;
  fill: #fff; }

.phone-number__label {
  display: inline-block;
  font-size: 1.4rem; }

.nav--mobile {
  display: flex;
  align-items: center;
  margin-right: -16px; }
  @media (min-width: 1024px) {
    .nav--mobile {
      display: none; } }
  .nav--mobile ul {
    display: flex;
    align-items: center; }

.nav__toggle {
  border-radius: 0; }
  .nav__toggle svg:last-child {
    display: none; }
  .nav__toggle.is-active {
    background-color: #2c3d5f; }
    .nav__toggle.is-active svg:first-child {
      display: none; }
    .nav__toggle.is-active svg:last-child {
      display: block;
      fill: #fff;
      width: 1.2rem;
      height: 1.2rem; }

.nav__wrapper--mobile {
  position: fixed;
  z-index: 99;
  top: 4.8rem;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #2c3d5f;
  color: #fff;
  display: none;
  overflow: auto; }
  .nav__wrapper--mobile.is-active {
    display: block; }
  .nav__wrapper--mobile > ul {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; }
  .nav__wrapper--mobile > ul > li {
    position: relative;
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2); }
  .nav__wrapper--mobile > ul > li > a {
    display: block;
    padding: 1.2rem 1.6rem; }
  .nav__wrapper--mobile > ul > li > .nav__sub-toggle {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    line-height: 1.75;
    color: #fff;
    padding: 1.2rem 1.6rem;
    border: 0;
    background-color: transparent; }
    .nav__wrapper--mobile > ul > li > .nav__sub-toggle svg {
      display: inline-block;
      width: 1.2rem;
      height: 1.2rem;
      fill: #fff; }
      .nav__wrapper--mobile > ul > li > .nav__sub-toggle svg:last-child {
        display: none; }
    .nav__wrapper--mobile > ul > li > .nav__sub-toggle.is-active svg:first-child {
      display: none; }
    .nav__wrapper--mobile > ul > li > .nav__sub-toggle.is-active svg:last-child {
      display: inline-block; }
    .nav__wrapper--mobile > ul > li > .nav__sub-toggle:hover {
      cursor: pointer; }
  .nav__wrapper--mobile > ul > li > ul {
    display: flex;
    flex-direction: column;
    position: absolute;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    overflow: hidden;
    white-space: wrap;
    width: 1px;
    height: 1px; }
    .nav__wrapper--mobile > ul > li > ul.is-active {
      position: static;
      clip: auto;
      clip-path: none;
      overflow: visible;
      white-space: normal;
      width: auto;
      height: auto; }
  .nav__wrapper--mobile > ul > li > ul > li {
    width: 100%;
    border-top: 1px dashed rgba(255, 255, 255, 0.1); }
  .nav__wrapper--mobile > ul > li > ul > li > a {
    display: block;
    font-size: 90%;
    padding: 1.2rem 1.6rem; }

.footer {
  width: 100%;
  padding: 3.6rem 0;
  background-color: #2c3d5f;
  color: #fff; }
  .footer [class*="grid-item"] {
    padding-bottom: 3.2rem; }
  @media (min-width: 640px) {
    .footer {
      padding: 6.4rem 0; } }
  .footer__logo {
    margin-bottom: 3.2rem; }
  .footer__title {
    font-weight: bold;
    margin-bottom: 1.2rem; }
    * + .footer__title {
      margin-top: 3.2rem; }
  .footer__divider {
    margin: 0 0 3.2rem;
    border: 0;
    border-top: 1px solid #fff; }
    @media (min-width: 640px) {
      .footer__divider {
        margin: 3.2rem 0 6.4rem; } }
  .footer__disclaimer {
    font-size: 1.2rem;
    text-align: center;
    line-height: 1.5;
    opacity: .66; }

.nav--footer li + li {
  margin-top: 0.8rem; }

.nav--social ul {
  display: flex;
  align-items: center; }

.nav--social li + li {
  margin-left: 1.2rem; }

.nav--social .social__icon {
  fill: #fff; }

.social__icon {
  width: 2rem;
  height: 2rem; }

.do-the-test {
  width: 100%; }
  .do-the-test .grid {
    flex-direction: column-reverse; }
    @media (min-width: 640px) {
      .do-the-test .grid {
        flex-direction: row; } }
  .do-the-test__image {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    height: 100%; }
    .do-the-test__image img {
      display: block;
      margin-bottom: -3.2rem;
      max-width: 40rem; }
      @media (min-width: 640px) {
        .do-the-test__image img {
          margin-bottom: -6.4rem; } }
  .do-the-test__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    max-width: 52rem; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }
